/**
 * @define SvgInline
 */

.SvgInline {
  fill: inherit;

  svg {
    fill: inherit;
  }
}
