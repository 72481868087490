/**
 * @define ProgressStep
 */

.ProgressStep {
  float: right;
  margin: 0.1em 0 0 var(--gutter-small);
  color: var(--colour-main);
  font-weight: bold;
  font-size: 0;
  font-size: var(--font-size-hero-copy-small);
  white-space: nowrap;
  vertical-align: top;
  cursor: default;
  user-select: none;

  &-label {
    display: inline-block;
    font-size: var(--font-size-large);
    line-height: 1.3;
    text-transform: uppercase;
    vertical-align: top;
  }

  &-position {
    display: inline-block;
    margin-left: var(--gutter-smaller);
    font-weight: 700;
    font-size: 2.25rem;
    font-family: var(--font-headings);
    line-height: 1;
    vertical-align: top;
  }

  &-total {
    display: inline-block;
    color: var(--colour-grey-mid);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.3;
    vertical-align: top;
  }

  &-position {
    font-weight: bold;
    font-family: var(--font-body);
  }

  &-label {
    display: none;

    @media (--mediumlarge) {
      display: inline-block;
    }
  }
}
