/*
@define RadioCards
*/

.RadioCards {
  display: flex;
  flex-direction: column;

  @media (--mediumlarge) {
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }
}
