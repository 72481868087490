@define-mixin checkbox $name {
  .$(name) {
    position: relative;
    display: inline-block;
    padding: var(--gutter-small) 0 0;

    &-input {
      position: absolute;
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
    }

    &-input + &-label {
      position: relative;
      display: block;
      padding-top: 0.3rem;
      padding-left: var(--gutter-large);
      color: var(--colour-brand);
      font-size: var(--font-size-normal);
      line-height: 1;
      cursor: pointer;
      transition: color var(--transition-default);
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    @mixin-content;

    &-input:checked + &-label {
      color: black;
    }

    &-input:checked + &-label::before,
    &-input:focus + &-label::before {
      border-color: var(--colour-main);
    }

    &-input:disabled + &-label::before {
      border-color: var(--colour-grey-mid) !important;
    }

    &-input:checked:disabled + &-label::before {
      background-color: var(--colour-grey-mid) !important;
    }

    &-helpers {
      position: relative;
      z-index: 0;
      margin-left: var(--gutter);
      font-size: var(--font-size-small);

      &::after {
        left: 0;
        display: block;
        width: 100%;
        padding: calc(var(--gutter-smaller) + 2px) var(--gutter-small)
          var(--gutter-smaller);
        border-radius: 0 0 var(--border-radius) var(--border-radius);
        font-weight: normal !important;
        font-size: var(--font-size-small);
        font-family: var(--font-headings);
        line-height: 1.2em;
        transition: opacity var(--transition-default);
        content: var(--unicode-nbsp);
        pointer-events: none;
      }

      &[data-hint]:not([data-hint=''])::after {
        color: var(--colour-grey-mid-dark);
        content: attr(data-hint);
      }
    }

    &.is-error {
      .$(name)-input + .$(name)-label {
        color: var(--colour-error);
      }

      .$(name)-input + .$(name)-label::before {
        border-color: var(--colour-error);
      }

      .$(name)-helpers[data-error]:not([data-error=''])::after {
        color: var(--colour-error);
        content: attr(data-error);
      }
    }

    &.is-error-orange {
      .$(name)-input + .$(name)-label {
        color: var(--colour-error-orange);
      }

      .$(name)-input + .$(name)-label::before {
        border-color: var(--colour-error-orange);
      }

      .$(name)-helpers[data-error]:not([data-error=''])::after {
        color: var(--colour-error-orange);
        content: attr(data-error);
      }
    }

    /* margins for series of checkboxes */

    & + &:not(.u-block) {
      margin-left: var(--gutter-small);
    }
  }
}
