/**
 * @define BreadcrumbList
 */

.BreadcrumbList {
  list-style-type: none;
}

.BreadcrumbList-item {
  position: relative;
  display: inline-block;
  color: var(--colour-grey-dark);
  font-family: var(--font-headings);

  & a {
    color: var(--colour-grey-mid-dark);
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:not(:last-child)::after {
    position: absolute;
    top: 50%;
    right: -12px;
    color: var(--colour-grey-dark);
    font-size: 0.8em;
    transform: translateY(-50%);
    content: '>';
    pointer-events: none;
  }
}
