/**
 * @define Input
 */

.Input {
  position: relative;
  display: inline-block;
  width: 300px;
  padding-top: 15px;
  overflow: hidden;
  vertical-align: top;
  transform: translateZ(0);
  backface-visibility: hidden;

  &.is-focused {
    transform: none;
  }

  &-field {
    position: relative;
    font-size: 0;
    transform: translateZ(0);
  }

  &-input {
    @mixin inputSimple;
    position: relative;
    width: 100%;
    min-height: var(--input-min-height);
    margin: 0;
    padding: var(--gutter-small) 0 var(--gutter-small);
    border: 0;
    border-bottom: 1px var(--colour-grey-mid) solid;
    border-bottom-color: var(--colour-main);
    border-radius: 0;
    color: var(--colour-brand);
    font-size: var(--font-size-input);
    font-family: var(--font-body);
    line-height: 1;
    cursor: pointer;
    appearance: none;
    caret-color: var(--colour-main);

    &:focus {
      outline: none;
      cursor: text;
    }

    &::placeholder {
      color: var(--colour-grey-mid);
      font-size: var(--font-size-normal);
      transition: transform 0.3s ease, opacity 0.3s ease;

      @media (--mediumlarge) {
        font-size: var(--font-size-copy);
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
    }
  }

  &:not(&--present) &-input:disabled {
    color: var(--colour-input-disabled);
  }

  &-input[type='date']::-webkit-clear-button,
  &-input[type='date']::-webkit-inner-spin-button {
    display: none;
  }

  &-input[type='date']::-webkit-calendar-picker-indicator {
    margin: -1px;
  }

  &-label {
    position: absolute;
    top: -12px;
    left: 0;
    display: block;
    color: var(--colour-brand);
    font-size: var(--font-size-small);
    line-height: 1;
    transform: translateY(5px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &-border {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--colour-main);
    transform: scaleX(0) translateZ(0);
    transform-origin: 0 0;
    backface-visibility: hidden;
    transition: all 0.2s ease-out;
    pointer-events: none;
  }

  &-input:invalid {
    box-shadow: none;
  }

  &-input:focus::placeholder {
    transform: translateY(-5px);
    opacity: 0;
  }

  &-input:not(:disabled):hover ~ &-border {
    background-color: var(--colour-main);
    transform: scaleX(1) translateZ(0);
  }

  &-input:not(:disabled):focus ~ &-border,
  &-input:not(:disabled).is-focused ~ &-border {
    background-color: var(--colour-main);
    transform: scaleX(1) translateZ(0);
  }

  &--fixed-label .Input-label {
    white-space: nowrap;
    transform: translateY(0);
    opacity: 1;
  }

  &.is-focused &-label,
  &-input:focus ~ &-label {
    color: var(--colour-main);
    transform: translateY(0);
    opacity: 1;
  }

  &-input:not(:placeholder-shown):not(.StripeElement) + &-label {
    transform: translateY(0);
    opacity: 1;
  }

  /* helpers (errors and hint text) */

  &-helpers {
    position: relative;
    font-size: var(--font-size-small);
    letter-spacing: -0.02em;
    transform: translateZ(0);
    backface-visibility: hidden;

    &::after {
      left: 0;
      display: block;
      width: 100%;
      padding: var(--gutter-small) 0;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      color: var(--colour-brand);
      font-size: var(--font-size-small);
      line-height: 1.2em;
      transition: opacity var(--transition-default);
      content: var(--unicode-nbsp);
      pointer-events: none;
    }

    &[data-hint]:not([data-hint=''])::after {
      content: attr(data-hint);
    }

    &[data-hint]:not([data-hint='']) {
      margin-bottom: var(--gutter);
    }
  }

  &.is-error {
    .Input-input {
      border-color: var(--colour-error);
      caret-color: var(--colour-error);
    }

    .Input-label {
      color: var(--colour-error);
    }

    .Input-border {
      background-color: var(--colour-error) !important;
    }

    .Input-helpers::after {
      color: var(--colour-error);
      font-weight: normal !important;
      font-family: var(--font-headings);
      text-align: right;
    }

    .Input-helpers[data-error]:not([data-error=''])::after {
      content: attr(data-error);
    }
  }

  &-hiviz {
    overflow: initial;
  }

  &-hiviz.is-error {
    .Input-helpers {
      margin-bottom: var(--gutter);
    }

    .Input-helpers::after {
      box-sizing: border-box;
      padding: var(--gutter-medium);
      padding-bottom: calc(var(--gutter-medium) - 2px);
      border-radius: var(--border-radius-toast);
      color: var(--colour-white) !important;
      font-size: var(--font-size-normal);
      text-align: left !important;
      background-color: var(--colour-error);
      transform: translateY(var(--gutter-small));
    }

    .Input-helpers::before {
      position: absolute;
      top: var(--gutter-smaller);
      left: var(--gutter-medium);
      display: block;
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--colour-error);
      border-left: 5px solid transparent;
      content: '';
    }

    .Input-helpers:not([data-error])::before,
    .Input-helpers:not([data-error])::after {
      display: none;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    height: 3rem;
    padding: 0 1rem;
    border-radius: 4px;
    background-color: var(--colour-grey-lighter4);

    .Input-label {
      top: -25px;
    }

    .Input-input {
      border-bottom: 0;

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px var(--colour-grey-lighter4) inset;
      }
    }
  }

  &-dateentry-field {
    width: 25%;

    .Input-helpers::after {
      width: calc(300% + var(--gutter));
    }

    @media (--medium) {
      width: 60px;

      .Input-helpers::after {
        width: calc(220px + var(--gutter));
      }
    }

    &-year {
      width: 25%;

      @media (--medium) {
        width: 100px;
      }
    }
  }

  /* disabled state */

  &-input:disabled + &-label {
    transform: translateY(0);
    opacity: 1;
  }

  &-input:disabled + &-border {
    display: none;
  }

  &-input:disabled {
    border-style: dashed;
    cursor: not-allowed;
  }

  &--present &-input:disabled {
    border-color: var(--colour-present-border);
    color: var(--colour-brand);
    cursor: default;
    opacity: 1;
    -webkit-text-fill-color: var(--colour-brand);
  }

  /* spanning container and label positioning */

  &--span {
    flex: 2 1 auto;
    width: 100%;

    .Input-field,
    .Input-input {
      width: 100%;
    }
  }

  /* margins for series of inputs */

  &:not(:last-child):not(.u-block) {
    margin-right: var(--gutter-small);
  }
}

/* Units */

.Input {
  &[class*='Input--unit-'] {
    .Input-input {
      padding-left: calc(var(--gutter) - 5px);
    }

    .Input-field::before {
      position: absolute;
      top: 20px;
      left: 0;
      z-index: 2;
      color: var(--colour-brand);
      font-size: var(--font-size-input);
      font-family: var(--font-body);
      line-height: 1;
      transform: translateY(-50%);
      transition: color var(--transition-default);
      pointer-events: none;
    }

    &:not(.is-disabled):hover .Input-field::before {
      color: var(--colour-grey-dark);
    }

    &:not(.is-disabled).is-focused .Input-field::before {
      color: var(--colour-main);
    }
  }

  &--unit-percent {
    .Input-input {
      padding-right: calc(var(--gutter) - 5px) !important;
      padding-left: 0 !important;
    }
  }

  &--unit-percent &-field::before {
    right: 0;
    left: inherit !important;
    left: unset !important;
    content: '%';
  }

  &--unit-gbp &-field::before,
  &--unit-pound &-field::before {
    content: '£';
  }

  &--unit-usd &-field::before,
  &--unit-dollar &-field::before {
    content: '$';
  }

  &--unit-eur &-field::before,
  &--unit-euro &-field::before {
    content: '€';
  }
}

/* icon font handling */

.Input[class*='Icons-'] {
  .Input-input {
    padding-right: var(--gutter);
  }

  &::before {
    position: absolute;
    top: 34px;
    right: 0;
    color: var(--colour-grey-mid);
    transform: translateY(-50%);
    transition: color var(--transition-default);
    pointer-events: none;
  }

  &:not(.is-disabled):hover::before {
    color: var(--colour-grey-dark);
  }

  &:not(.is-disabled).is-focused::before {
    color: var(--colour-main);
  }
}

.Input[class*='Icons-start-']::before {
  top: 50%;
  left: 0.75rem;
  font-size: 1.5rem;
}

.Input[class*='Icons-start-'] .Input-input {
  padding-right: 0;
  padding-left: 1.5rem;
}

.Input[class*='Icons-cc-']::before {
  top: 40px;
  font-size: 1.5em;
}

.Input.Input--no-label {
  &::before {
    top: 20px;
  }
}

/* stylelint-disable plugin/selector-bem-pattern */

.Input-pikaday {
  font-family: var(--font-body) !important;

  &.pika-single {
    border-radius: var(--border-radius);
    transform: translateY(var(--gutter-small));
  }

  &.is-above {
    transform: translateY(-20px);
  }

  &.pika-single.is-bound {
    @mixin inputBoxShadow var(--colour-brand);
  }

  .is-today .pika-button {
    color: var(--colour-main);
  }

  .pika-button:hover,
  .pika-row.pick-whole-week:hover .pika-button {
    color: white;
    background-color: var(--colour-main);
  }

  .is-selected .pika-button {
    border-radius: 3px;
    color: white;
    font-weight: normal !important;
    font-family: var(--font-headings);
    background-color: var(--colour-main);
    box-shadow: none;
  }

  .pika-label::after {
    margin-left: 3px;
    content: '\25be';
  }

  abbr {
    text-decoration: none;
  }

  .pika-button {
    text-align: center;
  }
}

/* stylelint-enable plugin/selector-bem-pattern */

.Input-datesplit {
  &:not(:last-child):not(.u-block) {
    margin-right: 0.9375rem;
  }

  &--day,
  &--month {
    max-width: 4.375rem;
  }

  &--year {
    max-width: 6.25rem;
  }

  & .Input-input {
    height: 2.8125rem;
    border: 2px solid var(--colour-brand);
    text-indent: 0.3125rem;
  }

  & .Input-helpers::after {
    padding: 0.3125rem;
    font-size: var(--font-size-small);
  }

  & .Input-input:hover,
  & .Input-input:focus {
    border: 2px solid var(--colour-main);
  }

  & .Input-input::placeholder {
    font-size: var(--font-size-normal);
  }

  &.is-error .Input-helpers::after {
    min-width: 5rem;
    padding: 5px;
    font-size: var(--font-size-small);
  }

  & .Input-label {
    top: -1rem;
  }
}
