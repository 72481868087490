/**
 * @define Toasts
 */

.Toasts {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  padding: 0;

  &-items {
    width: 100%;
    margin: 0 auto;

    @media (--medium) {
      width: var(--width-medium);
    }

    @media (--large) {
      width: var(--width-large);
    }
  }
}

.Toasts-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--gutter);
  border-radius: var(--border-radius-toast);
  color: white;
  font-size: var(--font-size-small);
  background-color: var(--colour-main);
  transition: all var(--transition-default);

  @media (--mediumlarge) {
    font-size: var(--font-size-normal);
  }

  &:first-child {
    margin-top: var(--gutter);
  }

  &:not(:last-child) {
    margin-bottom: var(--gutter);
  }

  &[data-type='message'] {
    border-color: var(--colour-toast-success-border);
    background-color: var(--colour-toast-success-background);

    a {
      color: var(--colour-toast-success-link);
    }
  }

  &[data-type='error'] {
    padding-right: var(--gutter-medium);
    color: white;
    background-color: var(--colour-error);
  }

  &-icon {
    display: block;
    float: left;
    margin: 0 var(--gutter) 0 0;

    @media (--medium) {
      transform: scale(1.2);
    }
  }

  &-close {
    right: var(--gutter);
    float: right;
    color: white !important;
    font-size: 1.4em;
    text-decoration: none;

    &:hover {
      color: color-mod(white a(90%));
    }
  }

  &.is-new {
    transform: scale(1.1) translateY(-20px) translateZ(0);
    opacity: 0;
  }

  &.is-hidden {
    transform: scale(0.9) translateZ(0);
    opacity: 0;
  }

  &.is-swiped-left {
    transform: translateZ(0) translateX(-150px);
  }

  &.is-swiped-right {
    transform: translateZ(0) translateX(150px);
  }

  &.is-flat {
    max-height: 0;
    margin-bottom: 0;
    padding: 0;
  }
}
