/**
 * HTML - basic reset for main element styles
 */

/* box model: http://goo.gl/quQpCV */

html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

svg path,
path {
  fill: inherit;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

button {
  font-family: var(--font-body);
  line-height: inherit;
}
