/**
 * @define Button
 */

.ButtonStacker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--medium) {
    flex-wrap: nowrap;
  }
}

.ButtonStacker .Button {
  flex: 2 1 55%;
  margin-top: var(--gutter-small);
  margin-right: 0 !important;

  @media (--medium) {
    flex: 2 1 auto;
    min-width: unset;
    margin-top: 0;

    &:not(:last-child):not(.u-block) {
      margin-right: var(--gutter-small) !important;
    }
  }
}
