/**
 * @define Header; weak
 */

.Header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.125rem;
  min-height: 3.125rem;
  padding-right: var(--gutter-large);
  padding-left: var(--gutter-large);
  color: white;
  background-color: var(--colour-brand);

  @media (--mediumlarge) {
    height: 6.25rem;
    min-height: 6.25rem;
    padding-right: var(--gutter-xlarge);
    padding-left: var(--gutter-xlarge);
  }

  &-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-help {
    position: absolute;
    top: 50%;
    right: calc(100% + var(--gutter));
    color: white;
    font-weight: bold;
    font-size: var(--font-size-tiny);
    line-height: 1.29;
    white-space: nowrap;
    transform: translateY(-50%);

    &-eri {
      position: absolute;
      top: 50%;
      right: calc(100% + var(--gutter));
      display: none;
      color: white;
      font-weight: bold;
      font-size: var(--font-size-tiny);
      white-space: nowrap;
      transform: translateY(-50%);

      @media (--mediumlarge) {
        display: block;
      }
    }
  }

  &-contact {
    position: relative;
    margin-left: var(--gutter);
    padding-left: var(--gutter);
    color: var(--colour-main);
    font-weight: bold;
    font-size: var(--font-size-tiny);
    font-family: var(--font-body);
    line-height: 1.17;

    a {
      font-weight: bold;
      font-family: var(--font-body);
      text-decoration: none;
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: white;
      content: '';
    }

    @media (--mediumlarge) {
      font-size: var(--font-size-tiny);
    }

    &-eri {
      position: relative;
      margin-left: var(--gutter);
      padding-left: var(--gutter);
      color: var(--colour-main);
      font-weight: bold;
      font-size: var(--font-size-tiny);
      font-family: var(--font-body);

      a {
        font-weight: bold;
        font-family: var(--font-body);
        text-decoration: none;
      }

      & .PhoneLink {
        float: right;
      }

      @media (--mediumlarge) {
        font-size: var(--font-size-small);

        & .PhoneLink {
          float: left;
        }

        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          background-color: white;
          content: '';
        }
      }
    }
  }
}
