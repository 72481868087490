/**
 * Site-wide variables
 */

/* prettier-ignore */

:root {

  /* fonts */

  --font-headings: 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  --font-body: 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;

  /* font sizes */

  --font-size-micro: 0.625rem; /* 10px */
  --font-size-tiny: 0.75rem; /* 12px */
  --font-size-small: 0.875rem; /* 14px */
  --font-size-normal: 1rem; /* 16px */
  --font-size-copy: 1.125rem; /* 18px */
  --font-size-large: 1.25rem; /* 20px */
  --font-size-larger: 1.375rem; /* 22px */
  --font-size-large-desktop: 1.5rem; /* 24px */
  --font-size-title-small: 1.875rem; /* 30px */
  --font-size-title-medium: 2.5rem; /* 40px */
  --font-size-title-desktop: 3.125rem; /* 50px */
  --font-size-hero: 3.125rem; /* 50px */
  --font-size-hero-desktop: 4.375rem; /* 70px */
  --font-size-hero-copy-small: 1.125rem; /* 18px */
  --font-size-hero-copy: 1.375rem; /* 22px */
  --font-size-hero-copy-desktop: 1.5rem; /* 24px */
  --font-size-hook: 1.625rem; /* 26px */
  --font-size-hook-desktop: 1.875rem; /* 30px */
  --font-size-input: 1.25rem; /* 20px */
  --font-size-textarea: 1.25rem; /* 20px */
  --font-size-price-mobile: 1.125rem; /* 22px */
  --font-size-price-desktop: 2.5rem; /* 40px */
  --font-size-label-desktop: 1.625rem; /* 26px */

  /* colours */

  --colour-main-active: #e14665;
  --colour-main: #F45372;
  --colour-main-button: color-mod(var(--colour-main) lightness(65%));
  --colour-black: #000;
  --colour-black-40: #0006;
  --colour-body: #fff;
  --colour-brand: #232129;
  --colour-hook-inverted: #424242;
  --colour-brand-dark: #1e1e1e;
  --colour-box-shadow: #00000014;
  --colour-error: #e24b6b;
  --colour-grey-info-text: #706e75;
  --colour-grey-copy: #444;
  --colour-grey-smoky: #888;
  --colour-grey-copy-light: #9b9b9b;
  --colour-grey-border: #979797;
  --colour-grey-border-light: #ddd;
  --colour-off-black-copy: #232129;
  --colour-grey-dark: #333;
  --colour-grey-dark-subtle: #E8E8E9;
  --colour-grey-light: #ccc;
  --colour-grey-lighter: #efefef;
  --colour-grey-lighter2: #f6f6f6;
  --colour-grey-lighter3: #eee;
  --colour-grey-lighter4: #f8f8f8;
  --colour-grey-lighter5: #f4f4f4;
  --colour-grey-lightest: #fefefe;
  --colour-grey-hooktext: #f7f7f7;
  --colour-grey-mid-dark: #666;
  --colour-grey-disabled: #D8D8D8;
  --colour-grey-mid-lighter: #858486;
  --colour-grey-mid: #999;
  --colour-grey-table-hover: #f7f7f7;
  --colour-green: #558e17;
  --colour-medium-green: #00B67A;
  --colour-input-disabled: #999;
  --colour-accent: #D34863;
  --colour-present-border: #dedede;
  --colour-main-active: #D34863;
  --colour-main-select: color-mod(var(--colour-main) a(60%));
  --colour-main-button: color-mod(var(--colour-main) lightness(70%));
  --colour-table-even: #e0e0e0;
  --colour-table-odd: #efefef;
  --colour-tenants-button: color-mod(var(--colour-main) lightness(60%));
  --colour-tenant-lead-main: #fcd4db;
  --colour-tertiary-active: #212121;
  --colour-tertiary: #444;
  --colour-white: #fff;
  --colour-toast-success-background: #558e17;
  --colour-toast-success-border: #406b11;
  --colour-toast-success-link: #abc78c;
  --colour-zoopla: #6A138E;
  --colour-tip: #FFF1EB;
  --colour-orange: #FAD6C7;
  --colour-light-orange: #FDE7DD;
  --colour-error-orange: #f67114;
  --colour-focus: #ff9db3;
  --colour-badge-grey: #c7c7c8;
  --colour-strong-red: #F44336;

  /* transitions */

  --transition-time: 0.25s;
  --transition-default: var(--transition-time) ease;

  /* Buttons & Forms */

  --border-radius: 3px;
  --border-radius-checkbox: 4px;
  --border-radius-button: 2px;
  --border-radius-formgroup: 6px;
  --border-radius-modal: 6px;
  --border-radius-toast: 2px;
  --border-radius-pill: 12px;
  --border-radius-round-top: 0.5rem 0.5rem 0 0;

  /* margins and spacing */

  --gutter-tiny: 1px;
  --gutter-smaller: 5px;
  --gutter-small: 10px;
  --gutter-small-medium: 12px;
  --gutter-medium: 15px;
  --gutter: 24px;
  --gutter-double: 40px;
  --gutter-large: 30px;
  --gutter-xlarge: 50px;
  --gutter-xxlarge: 80px;
  --gutter-xxxlarge: 115px;

  --button-padding-large: 18px;
  --button-padding: 15px;
  --button-padding-small: 12px;
  --button-padding-filter: 8px 12px;

  --button-padding-navigation-bar: 23px 15px 24px;
  --padding-navigation-bar-search: 15px 0 15px;

  --input-width-default: 300px;
  --input-min-height: 41px;

  --navigation-list-width: 300px;

  --hub-filters-width: 18.75rem;
  --hub-filters-right: 3%;
  --hub-filters-space: calc(var(--hub-filters-width) + var(--hub-filters-right) + 2%);

  /* unicode shortcuts */

  --unicode-nbsp: '\00a0';

  /* content padding */
  --content-padding-tiny: 0.5rem;
  --content-padding-smaller: 1rem;
  --content-padding-small: 1.25rem;
  --content-padding-small-medium: 1.75rem;
  --content-padding-mobile: 1.875rem;
  --content-padding-desktop: 3.75rem;
  --content-padding-large-desktop: 9.375rem;
  --content-padding-larger-desktop: 10.375rem;
  --content-padding-titles: 3.125rem;
  --content-padding: 2.5rem;
  --content-padding-large: 6.25rem;
  --content-padding-xlarge: 15.625rem;

  /* section margin */
  --section-margin-mobile: 6.25rem;
  --section-margin-desktop: 15.625rem;

  /* heading margin */
  --heading-margin-mobile: 3.125rem;

  /* content widths */
  --width-small: 400px;
  --width-medium: 720px;
  --width-large: 980px;
  --width-xlarge: 1200px;
  --content-width-large: 50rem;
  --content-width-xlarge: 56.25rem;

  /* z-index */

  --zindex-sendback: -1;
  --zindex-mainnav: 10;
  --zindex-cdscta: 10;
  --zindex-subnav: 11;
  --zindex-filterform: 10;
  --zindex-stickyvideo__featureimage: 2;
  --zindex-cookiebar: 100;
  --zindex-hubmap: 9;
  --zindex-autocompletelist: 9;

  /* HomeImageLinks component sizes */

  --image-mobile-height: 15.625rem;
  --image-mobile-width: 12.5rem;
  --image-mediumlarge-height: 22.5rem;
  --image-mediumlarge-width: 18.125rem;
  --image-xxlarge-height: 25rem;
  --image-xxlarge-width: 20.625rem;
  --image-xxxlarge-height: 31.875rem;
  --image-xxxlarge-width: 26.25rem;
  --linktext-height: 3.125rem;

  --image-mobile-height-hovered: 16.875rem;
  --image-mobile-width-hovered: 13.75rem;
  --image-mediumlarge-height-hovered: 24.75rem;
  --image-mediumlarge-width-hovered: 20rem;
  --image-xxlarge-height-hovered: 26.25rem;
  --image-xxlarge-width-hovered: 21.875rem;
  --image-xxxlarge-height-hovered: 31.875rem;
  --image-xxxlarge-width-hovered: 27.5rem;
  --linktext-height-hovered: 7.1875rem;

  /* nav dimensions */
  --subnav-item-height: 4.6875rem;


  /* media queries */

  @custom-media --small (min-width: 420px);
  @custom-media --mediumsmall (min-width: 550px);
  @custom-media --medium (min-width: 720px);
  @custom-media --mediumlarge (min-width: 768px);
  @custom-media --large (min-width: 980px);
  @custom-media --xlarge (min-width: 1000px);
  @custom-media --xxlarge (min-width: 1440px);
  @custom-media --xxxlarge (min-width: 1680px);
  @custom-media --smallheight (min-height: 570px);
  @custom-media --mediumheight (min-height: 1000px);

  @custom-media --ie11 (-ms-high-contrast: none);
}
