/*
@define StickyHeader
*/

.StickyHeader {
  position: sticky;
  top: 0;
  z-index: var(--zindex-mainnav);

  &-scrolled.StickyHeader--light {
    box-shadow: 0 0 15px var(--colour-grey-lighter3);
    opacity: 0.9;
  }

  &-scrolled.StickyHeader--dark {
    border: none;
    box-shadow: 0 4px 12px 0 rgba(100, 99, 107, 0.5);
  }
}
