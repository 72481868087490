/**
 * @define PhoneLink
 */

.PhoneLink {
  a { display: none; }

  span { display: inline-block; }

  html.touch & {
    a { display: inline-block;; }

    span { display: none; }
  }
}
