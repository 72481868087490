/**
 * @define LogoItem
 */

.LogoItem {
  flex: 0 1 auto;
  margin: 0 var(--gutter);
  opacity: 0.9;
  filter: grayscale(100%);

  &:not(:last-child) {
    margin-bottom: 45px;

    @media (--mediumlarge) {
      margin-bottom: 0;
    }
  }

  &-img {
    max-width: 100px;

    @media (--mediumlarge) {
      max-width: 120px;
    }
  }

  &-img-large {
    max-width: 200px;
  }

  a& {
    cursor: pointer;
    transition: var(--transition-default) transform;

    &:hover {
      transform: scale(1.05);
    }
  }
}
