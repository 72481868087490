/**
 * @define Heading
 */

.Heading {
  margin-bottom: var(--content-padding-titles);
  font-weight: bold;
  font-size: 1.875rem;
  text-align: center;

  @media (--medium) {
    font-size: 2.5rem;
  }
}
