/**
 * @define Link
 */

.Link,
a {
  color: var(--colour-main);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:not([class]):hover {
    color: var(--colour-accent);
  }
}
