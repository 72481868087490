.Svg {
	background: url("sprites.svg") no-repeat;
}

.Svg-amex-logo {
	background-position: 71.20743034055728% 30.4093567251462%;
}

.Svg-amex-logo {
	width: 53px;
	height: 21px;
}

.Svg-document-agents {
	background-position: 100% 0;
}

.Svg-document-agents {
	width: 46px;
	height: 60px;
}

.Svg-document-default {
	background-position: 100% 19.801980198019802%;
}

.Svg-document-default {
	width: 46px;
	height: 60px;
}

.Svg-document-tenants {
	background-position: 100% 39.603960396039604%;
}

.Svg-document-tenants {
	width: 46px;
	height: 60px;
}

.Svg-error-404 {
	background-position: 77.56410256410257% 75.22935779816514%;
}

.Svg-error-404 {
	width: 64px;
	height: 36px;
}

.Svg-gear-wheel {
	background-position: 81.89655172413794% 37.91044776119403%;
}

.Svg-gear-wheel {
	width: 28px;
	height: 28px;
}

.Svg-gocardless {
	background-position: 0 0;
}

.Svg-gocardless {
	width: 174px;
	height: 23px;
}

.Svg-logo-bare {
	background-position: 95.93023255813954% 73.18611987381703%;
}

.Svg-logo-bare {
	width: 32px;
	height: 46px;
}

.Svg-logo-eri-desktop {
	background-position: 0 59.515570934256054%;
}

.Svg-logo-eri-desktop {
	width: 112px;
	height: 74px;
}

.Svg-logo-eri-mobile {
	background-position: 49.354838709677416% 38.71951219512195%;
}

.Svg-logo-eri-mobile {
	width: 66px;
	height: 35px;
}

.Svg-logo-eri-white-desktop {
	background-position: 42.42424242424242% 59.515570934256054%;
}

.Svg-logo-eri-white-desktop {
	width: 112px;
	height: 74px;
}

.Svg-logo-eri-white-mobile {
	background-position: 70.64516129032258% 38.71951219512195%;
}

.Svg-logo-eri-white-mobile {
	width: 66px;
	height: 35px;
}

.Svg-logo-large {
	background-position: 0 8.88030888030888%;
}

.Svg-logo-large {
	width: 156px;
	height: 104px;
}

.Svg-logo-medium {
	background-position: 82.96296296296296% 58.9041095890411%;
}

.Svg-logo-medium {
	width: 106px;
	height: 71px;
}

.Svg-logo-small {
	background-position: 34.41558441558441% 77.60252365930599%;
}

.Svg-logo-small {
	width: 68px;
	height: 46px;
}

.Svg-logo-white-bare {
	background-position: 0 100%;
}

.Svg-logo-white-bare {
	width: 32px;
	height: 46px;
}

.Svg-logo-white-large {
	background-position: 79.0909090909091% 0;
}

.Svg-logo-white-large {
	width: 156px;
	height: 104px;
}

.Svg-logo-white-medium {
	background-position: 0 84.24657534246575%;
}

.Svg-logo-white-medium {
	width: 106px;
	height: 71px;
}

.Svg-logo-white-small {
	background-position: 56.493506493506494% 77.60252365930599%;
}

.Svg-logo-white-small {
	width: 68px;
	height: 46px;
}

.Svg-mastercard-logo {
	background-position: 100% 83.23353293413173%;
}

.Svg-mastercard-logo {
	width: 46px;
	height: 29px;
}

.Svg-stripe {
	background-position: 0 39.937106918238996%;
}

.Svg-stripe {
	width: 153px;
	height: 45px;
}

.Svg-visa-logo {
	background-position: 54.375% 30.498533724340177%;
}

.Svg-visa-logo {
	width: 56px;
	height: 22px;
}

.Svg-zero-marker {
	background-position: 94.82758620689656% 57.87781350482315%;
}

.Svg-zero-marker {
	width: 28px;
	height: 52px;
}

