/**
 * @define Grid
 */

/* stylelint-disable rule-empty-line-before */

.Grid {
  &--whole {
    width: 100%;
  }

  &--one-half {
    width: 50%;
  }

  &--one-third {
    width: 33.3%;
  }

  &--two-thirds {
    width: 66.6%;
  }

  &--one-quarter {
    width: 25%;
  }

  &--three-quarters {
    width: 75%;
  }

  &--one-fifth {
    width: 20%;
  }

  &--two-fifths {
    width: 40%;
  }

  &--three-fifths {
    width: 60%;
  }

  &--four-fifths {
    width: 80%;
  }

  &--one-sixth {
    width: 16.666666667%;
  }

  &--five-sixths {
    width: 83.333333333%;
  }

  @media (--medium) {
    &--medium-whole {
      width: 100%;
    }

    &--medium-one-half {
      width: 50%;
    }

    &--medium-one-third {
      width: 33.3%;
    }

    &--medium-two-thirds {
      width: 66.6%;
    }

    &--medium-one-quarter {
      width: 25%;
    }

    &--medium-three-quarters {
      width: 75%;
    }

    &--medium-one-fifth {
      width: 20%;
    }

    &--medium-two-fifths {
      width: 40%;
    }

    &--medium-three-fifths {
      width: 60%;
    }

    &--medium-four-fifths {
      width: 80%;
    }

    &--medium-one-sixth {
      width: 16.666666667%;
    }

    &--medium-five-sixths {
      width: 83.333333333%;
    }
  }

  @media (--large) {
    &--large-whole {
      width: 100%;
    }

    &--large-one-half {
      width: 50%;
    }

    &--large-one-third {
      width: 33.3%;
    }

    &--large-two-thirds {
      width: 66.6%;
    }

    &--large-one-quarter {
      width: 25%;
    }

    &--large-three-quarters {
      width: 75%;
    }

    &--large-one-fifth {
      width: 20%;
    }

    &--large-two-fifths {
      width: 40%;
    }

    &--large-three-fifths {
      width: 60%;
    }

    &--large-four-fifths {
      width: 80%;
    }

    &--large-one-sixth {
      width: 16.666666667%;
    }

    &--large-five-sixths {
      width: 83.333333333%;
    }
  }
}

/* stylelint-enable rule-empty-line-before */
