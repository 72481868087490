.TextWell {
  display: block;
  max-height: 500px;
  margin-top: var(--gutter);
  padding: var(--gutter);
  border-radius: var(--border-radius-toast);
  overflow: hidden;
  color: var(--colour-brand);
  font-size: var(--font-size-hero-copy-small);
  background-color: var(--colour-grey-lighter3);
  opacity: 1;
  transition: all var(--transition-default);

  &--hidden {
    max-height: 0;
    padding: 0 var(--gutter);
    border-color: transparent;
    opacity: 0;
  }
}
