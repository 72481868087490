/**
 * @define Subheading
 */

.Subheading {
  padding-bottom: var(--content-padding-titles);
  font-size: var(--font-size-large);
  text-align: center;

  @media (--mediumlarge) {
    padding-bottom: var(--content-padding-large);
    font-size: var(--font-size-large-desktop);
  }
}
