/**
 * @define ContentRow
 */

.ContentRow {
  flex: 1 0 auto;
  width: 100%;

  &--clickable {
    color: var(--colour-brand);
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--colour-grey-lighter2);
    }
  }

  &--nav-margin-top {
    margin-top: 100px;

    .logged-in-as & {
      margin-top: 168px;
    }
  }

  &-contents {
    max-width: var(--width-large);
    margin: 0 auto;
    padding: var(--gutter);

    @media (--large) {
      padding: var(--gutter);
    }
  }

  &-inner {
    max-width: var(--width-large);
    margin: 0 auto;
    padding: var(--gutter) var(--gutter-large);

    @media (--large) {
      max-width: var(--width-large);
      padding: var(--gutter);
    }
    padding-top: 0;
    padding-bottom: 100px;

    @media (--mediumlarge) {
      padding-top: var(--gutter);
    }

    &--wide {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
    }
  }

  /*
  For use in agent portal editing areas, spans full width of page with border
  */

  &-span {
    position: relative;
    display: block;
    flex-grow: 0;

    &:not(:last-child) {
      border-bottom: var(--colour-grey-lighter) 1px solid;
    }

    &-header {
      margin: var(--gutter-xlarge) 0 var(--gutter);

      &-title {
        font-weight: bold;
        font-size: var(--font-size-hero);
        font-family: var(--font-body);
      }

      &-subtitle {
        font-size: var(--font-size-normal);
      }

      &-cols {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: var(--gutter-large);
      }

      &-col {
        min-width: 200px;
        padding: 0 var(--gutter) 0 0;

        &:not(:last-child) {
          padding-right: var(--gutter-xlarge);
        }
      }
    }

    &-content {
      padding-top: var(--gutter-large);
      padding-bottom: var(--gutter-large);

      &-title {
        font-weight: 800;
        font-size: var(--font-size-large-desktop);
      }

      &-table {
        width: 100%;

        &-row {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            border-bottom: var(--colour-grey-lighter) 1px solid;
          }
        }

        &-col {
          flex: 2 1 auto;
          padding: var(--gutter-large) 0;

          &:first-child {
            flex: 0 0 30%;
            color: var(--colour-grey-mid);
          }

          &:first-child:last-child,
          &:not(:first-child) {
            color: var(--colour-grey-copy);
          }

          &:first-child:last-child {
            flex-basis: 100%;
          }
        }
      }
    }

    &-edit {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: var(--gutter-xxlarge);
      border-left: transparent 1px solid;
      color: var(--colour-grey-light);
      background-color: var(--colour-grey-lightest);
      transition: background-color 0.3s ease, border-color 0.3s ease;

      &-icon {
        transition: color 0.3s ease;
      }

      &-label {
        margin-top: var(--gutter-small);
        font-size: var(--font-size-tiny);
        text-transform: uppercase;
        transition: color 0.3s ease;
      }
    }

    &:hover {
      .ContentRow-span-edit {
        border-color: var(--colour-grey-lighter);
        background-color: var(--colour-grey-lighter2);
      }

      .ContentRow-span-edit-icon {
        color: var(--colour-main);
      }

      .ContentRow-span-edit-label {
        color: var(--colour-grey-dark);
      }
    }
  }

  &-span-group {
    border-bottom: 0 !important;

    .ContentRow-inner {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .ContentRow-span-content {
      padding-bottom: 0;
    }
  }

  &-button-container {
    display: flex;
    flex-wrap: wrap;

    @media (--large) {
      flex-wrap: nowrap;
    }
  }
}
