/*
@define RadioCard
*/

.RadioCard {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid var(--colour-grey-border);
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  &-input {
    margin-right: 0.3125rem;
  }

  /* override default label style from checkbox.css */

  .RadioCard-input + .RadioCard-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &::before {
      top: 0.625rem;
    }

    @media (--mediumlarge) {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 2.5rem;

      &::before {
        top: 50%;
      }
    }
  }

  &-labelCopy {
    font-weight: bold;
    font-size: var(--font-size-small);

    @media (--mediumlarge) {
      font-size: var(--font-size-label-desktop);
    }
  }

  &-labelCopySub {
    font-weight: normal;
    font-size: var(--font-size-micro);

    @media (--mediumlarge) {
      display: none;
    }
  }

  &-labelPrice {
    color: var(--colour-main);
    font-weight: bold;
    font-size: var(--font-size-price-mobile);
    text-align: center;

    @media (--mediumlarge) {
      font-size: var(--font-size-price-desktop);
      text-align: left;
    }
  }

  &-labelPriceSub {
    color: var(--colour-brand);
    font-weight: normal;
    font-size: var(--font-size-micro);
    text-align: center;

    @media (--mediumlarge) {
      font-size: var(--font-size-normal);
      text-align: left;
    }
  }

  @media (--mediumlarge) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 21.875rem;
    height: 13.75rem;

    &:not(:last-child) {
      margin-right: 2.5rem;
      margin-bottom: 0;
    }
  }
}
