/**
 * @define TextArea
 */

.TextArea {
  position: relative;
  display: block;
  width: 300px;
  padding-top: 15px;
  overflow: hidden;
  vertical-align: top;
  transform: translateZ(0);
  backface-visibility: hidden;

  &.is-focused {
    transform: none;
  }

  &-field {
    position: relative;
    font-size: 0;
    transform: translateZ(0);
  }

  &-textarea {
    @mixin inputSimple;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    min-height: var(--input-min-height);
    margin: 0;
    padding: var(--gutter-small) 0 var(--gutter-small);
    border: 0;
    border-bottom: 1px var(--colour-grey-mid) solid;
    border-radius: 0;
    color: var(--colour-brand);
    font-size: var(--font-size-textarea);
    font-family: var(--font-body);
    line-height: 1.1;
    cursor: pointer;
    appearance: none;
    resize: none;
    caret-color: var(--colour-main);

    &:focus {
      outline: none;
      cursor: text;
    }

    &::placeholder {
      color: var(--colour-grey-mid);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &:not(:disabled) {
      border-bottom-color: var(--colour-main);
      caret-color: var(--colour-main);
    }
  }

  &:not(&--present) &-textarea:disabled {
    color: var(--colour-input-disabled);
  }

  &-label {
    position: absolute;
    top: -12px;
    left: 0;
    display: block;
    color: var(--colour-grey-mid);
    font-size: var(--font-size-small);
    line-height: 1;
    transform: translateY(5px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &-border {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--colour-grey-dark);
    transform: scaleX(0) translateZ(0);
    transform-origin: 0 0;
    backface-visibility: hidden;
    transition: all 0.2s ease-out;
    pointer-events: none;
  }

  &-textarea:invalid {
    box-shadow: none;
  }

  &-textarea:focus::placeholder {
    transform: translateY(-5px);
    opacity: 0;
  }

  &-textarea:not(:disabled):hover ~ &-border {
    background-color: var(--colour-main);
    transform: scaleX(1) translateZ(0);
  }

  &-textarea:not(:disabled):focus ~ &-border {
    background-color: var(--colour-main);
    transform: scaleX(1) translateZ(0);
  }

  &-textarea:focus ~ &-label {
    color: var(--colour-main);
    transform: translateY(0);
    opacity: 1;
  }

  &-textarea:not(:placeholder-shown) ~ &-label {
    transform: translateY(0);
    opacity: 1;
  }

  /* helpers (errors and hint text) */

  &-helpers {
    position: relative;
    font-size: var(--font-size-small);
    letter-spacing: -0.02em;
    transform: translateZ(0);
    backface-visibility: hidden;

    &::after {
      left: 0;
      display: block;
      width: 100%;
      padding: var(--gutter-small) 0;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      color: var(--colour-grey-mid);
      font-size: var(--font-size-small);
      line-height: 1.2em;
      transition: opacity var(--transition-default);
      content: var(--unicode-nbsp);
      pointer-events: none;
    }

    &[data-hint]:not([data-hint=''])::after {
      content: attr(data-hint);
    }

    &[data-hint]:not([data-hint='']) {
      margin-bottom: var(--gutter);
    }
  }

  &.is-error {
    .TextArea-textarea {
      border-color: var(--colour-error);
      caret-color: var(--colour-error);
    }

    .TextArea-label {
      color: var(--colour-error);
    }

    .TextArea-border {
      background-color: var(--colour-error) !important;
    }

    .TextArea-helpers::after {
      color: var(--colour-error);
      font-weight: normal !important;
      font-family: var(--font-headings);
      text-align: right;
    }

    .TextArea-helpers[data-error]:not([data-error=''])::after {
      content: attr(data-error);
    }
  }

  &-hiviz {
    overflow: initial;
  }

  &-hiviz.is-error {
    .TextArea-helpers {
      margin-bottom: var(--gutter);
    }

    .TextArea-helpers::after {
      box-sizing: border-box;
      padding: var(--gutter-medium);
      padding-bottom: calc(var(--gutter-medium) - 2px);
      border-radius: var(--border-radius-toast);
      color: var(--colour-white) !important;
      font-size: var(--font-size-normal);
      text-align: left !important;
      background-color: var(--colour-error);
      transform: translateY(var(--gutter-small));
    }

    .TextArea-helpers::before {
      position: absolute;
      top: var(--gutter-smaller);
      left: var(--gutter-medium);
      display: block;
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--colour-error);
      border-left: 5px solid transparent;
      content: '';
    }
  }

  /* disabled state */

  &-textarea:disabled + &-label {
    transform: translateY(0);
    opacity: 1;
  }

  &-textarea:disabled + &-border {
    display: none;
  }

  &-textarea:disabled {
    border-style: dashed;
    cursor: not-allowed;
  }

  &--present &-textarea:disabled {
    border-color: var(--colour-present-border);
    color: var(--colour-brand);
    cursor: default;
    opacity: 1;
    -webkit-text-fill-color: var(--colour-brand);
  }

  /* spanning container and label positioning */

  &--span {
    flex: 2 1 auto;
    width: 100%;

    .TextArea-field,
    .TextArea-textarea {
      width: 100%;
    }
  }
}
