/**
 * @define Autocomplete
 */

.Autocomplete {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &-list {
    position: absolute;
    top: 3.75rem;
    z-index: 100;
    width: 100%;
    max-height: 160px;
    margin: 0;
    padding: 0;
    overflow: scroll;
    background-color: white;
    box-shadow: 0 2px 4px var(--colour-brand);
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.5rem;
    margin: 0 !important;
    padding: 0 1.25rem;
    color: var(--colour-brand);
    list-style: none;
    background-color: white;
  }

  &-postcode {
    color: #ccc;
  }

  &-item:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
}
