/**
 * @define DocumentIcons
 */

.DocumentIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 0;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
    border-radius: var(--border-radius-formgroup);
    color: white;
    font-size: initial;
    text-align: center;
    text-decoration: none;
    background-color: var(--colour-main);
    cursor: pointer;
    transition: all var(--transition-default);

    &:hover {
      background-color: var(--colour-accent);
    }
  }

  &-item:not(:last-child) {
    margin-bottom: var(--gutter-small);

    @media (--mediumlarge) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  &-icon {
    display: block;
    padding: 10px 0 0;
  }

  &-label {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
  }

  @media (--mediumlarge) {
    flex-direction: row;
    justify-content: flex-start;
  }
}
