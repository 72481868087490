/**
 * @define Spinner
 */

.Spinner {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;

  &-dot {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--colour-main);
    animation: lds-default 1.2s linear infinite;

    &:nth-child(1) {
      top: 29px;
      left: 53px;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: 18px;
      left: 50px;
      animation-delay: -0.1s;
    }

    &:nth-child(3) {
      top: 9px;
      left: 41px;
      animation-delay: -0.2s;
    }

    &:nth-child(4) {
      top: 6px;
      left: 29px;
      animation-delay: -0.3s;
    }

    &:nth-child(5) {
      top: 9px;
      left: 18px;
      animation-delay: -0.4s;
    }

    &:nth-child(6) {
      top: 18px;
      left: 9px;
      animation-delay: -0.5s;
    }

    &:nth-child(7) {
      top: 29px;
      left: 6px;
      animation-delay: -0.6s;
    }

    &:nth-child(8) {
      top: 41px;
      left: 9px;
      animation-delay: -0.7s;
    }

    &:nth-child(9) {
      top: 50px;
      left: 18px;
      animation-delay: -0.8s;
    }

    &:nth-child(10) {
      top: 53px;
      left: 29px;
      animation-delay: -0.9s;
    }

    &:nth-child(11) {
      top: 50px;
      left: 41px;
      animation-delay: -1s;
    }

    &:nth-child(12) {
      top: 41px;
      left: 50px;
      animation-delay: -1.1s;
    }
  }
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}
