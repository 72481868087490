/**
* @define Video
*/

.Video {
  background-position: center;
  background-size: cover;

  &-contents {
    position: relative;
    height: auto;
    margin: var(--gutter) auto 80px;
    padding: 0;
    background-position: center;
    background-size: cover;
    cursor: pointer;

    @media (--medium) {
      margin: var(--gutter) auto;
    }

    @media (--large) {
      max-height: 600px;
    }
  }

  &-aspect {
    height: 0;
    padding-bottom: 56.25%;
  }

  &-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &-cta {
    display: flex;
    margin: var(--gutter);
  }

  &-cta-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: white;
    font-size: 24px;
    background-color: var(--colour-main);
  }

  &-cta-text {
    display: none;

    @media (--medium) {
      position: relative;
      right: auto;
      bottom: auto;
      left: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      padding: 0 var(--gutter);
      color: white;
      font-weight: bold;
      text-align: left;
      background-color: var(--colour-main);
    }
  }

  &-contents:hover {
    .Video-cta-icon,
    .Video-cta-text {
      background-color: var(--colour-accent);
    }
  }
}
