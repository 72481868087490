.TextWellCard {
  width: 100%;
  margin-top: 0.75rem;
  padding: var(--gutter);
  border-radius: var(--border-radius-modal);
  color: var(--colour-brand);
  background-color: var(--colour-grey-lighter4);

  &-title {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.33;
    text-align: center;
    text-transform: capitalize;

    &::after {
      display: block;
      height: 1px;
      margin: 0.7rem 0;
      border: solid 1px #d8d8d8;
      content: '';
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.625rem;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25;

    &--left-content {
      color: var(--colour-grey-border);
    }

    &--right-content {
      color: var(--colour-brand);
      font-weight: bold;
      text-align: right;
    }
  }

  &-footer {
    color: var(--colour-grey-border);
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.29;

    &::before {
      display: block;
      height: 1px;
      margin: 0.7rem 0;
      border: solid 1px #d8d8d8;
      content: '';
    }
  }
}
